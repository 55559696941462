import {
  Collapse,
  Rating,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import axiosInstance from "../../auth/axiosInstance";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import Loading from "../structure/Loading";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import "../customCss/Cours.css";

const MesCoursDetails = () => {
  const { idCours } = useParams();
  const [selectedCours, setSelectedCours] = useState(null);
  const [numRatee, setNumRatee] = useState(0);
  const [compareTime, setCompareTime] = useState(null);
  const [disableStart, setDisableStart] = useState(false);
  const [rateeClicked, setRateeClicked] = useState(false);
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user } = AuthData();
  const daysOfWeek = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const coursLevelMapping = {
    College_1: "1ère Collège",
    College_2: "2ème Collège",
    College_3: "3ème Collège",
    T_C: "Tronc Commun",
    BAC_1: "1ère Année Bac",
    BAC_2: "2ème Année Bac",
    Universitaire: "Universitaire",
    MP: "Prépa MP",
    PSI: "Prépa PSI",
    TSI: "Prépa TSI",
    ECS: "Prépa ECS",
    ECT: "Prépa ECT",
    Tout: "Tous Niveaux",
    Autre: "Autre",
  };

  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");

  useEffect(() => {
    const fetchCoursDetails = async () => {
      try {
        const res = await axiosInstance.get(
          `/mescours/ids?idCours=${idCours}&emailEleve=${user.name}`
        );
        if (res.data) {
          const response = await axiosInstance.get(
            `/cours/i?idCours=${idCours}`
          );
          setSelectedCours(response.data);
          setRating(res.data.rating);
          setNumRatee(res.data.ratee);
          setCompareTime(
            res.data.rateeDeux ? res.data.rateeDeux : res.data.rateeUne
          );
        } else {
          setSelectedCours(null);
        }
      } catch (error) {
        console.error("Error ");
      } finally {
        setLoading(false);
      }
    };
    fetchCoursDetails();
  }, [idCours, user.name]);

  useEffect(() => {
    if (compareTime) {
      const currentTime = new Date();
      const compareDateTime = new Date(
        compareTime[0],
        compareTime[1] - 1,
        compareTime[2]
      );
      const sameDayMonthYear =
        compareDateTime.getDate() === currentTime.getDate() &&
        compareDateTime.getMonth() === currentTime.getMonth() &&
        compareDateTime.getFullYear() === currentTime.getFullYear();
      setDisableStart(sameDayMonthYear);
    }
  }, [compareTime]);

  if (loading) {
    return <Loading />;
  }

  if (!selectedCours) {
    return <Typography variant="h4">Cours Indisponible </Typography>;
  }
  const {
    title,
    coursSubject,
    coursLevel,
    description,
    price,
    date_d,
    date_s,
    date_t,
  } = selectedCours;

  const coursLevelFull = coursLevelMapping[coursLevel] || coursLevel;

  const choosePicture = (coursSubject, coursLevel) => {
    const specialLevels = [
      "BAC_1",
      "BAC_2",
      "MP",
      "PSI",
      "TSI",
      "ECS",
      "ECT",
      "Universitaire",
    ];

    switch (coursSubject) {
      case "maths":
        return specialLevels.includes(coursLevel)
          ? "/img/maths_ad.jpg"
          : "/img/maths.jpg";
      case "pc":
        return specialLevels.includes(coursLevel)
          ? "/img/pc_ad.jpg"
          : "/img/pc.jpg";
      case "science_ingenieur":
        return specialLevels.includes(coursLevel)
          ? "/img/si_ad.jpg"
          : "/img/si.jpg";
      case "svt":
        return specialLevels.includes(coursLevel)
          ? "/img/svt_ad.jpg"
          : "/img/svt.jpg";
      case "arabe":
        return "/img/arabe.jpg";
      case "anglais":
        return specialLevels.includes(coursLevel)
          ? "/img/eng_ad.jpg"
          : "/img/english.jpg";
      case "francais":
        return specialLevels.includes(coursLevel)
          ? "/img/fr_ad.jpg"
          : "/img/fr.jpg";
      case "espagnol":
        return "/img/espagnol.jpg";
      case "education_islamique":
        return "/img/E_I.jpg";
      case "philosophie":
        return "/img/philosophie.jpg";
      case "histoire":
        return specialLevels.includes(coursLevel)
          ? "/img/histoire_ad.jpg"
          : "/img/histoire.jpg";
      case "info":
        return "/img/info.jpg";
      case "electronique":
        return "/img/electronique.jpg";
      case "economie":
        return specialLevels.includes(coursLevel)
          ? "/img/eco_ad.jpg"
          : "/img/economie.jpg";
      case "autre":
        return "/img/autre.jpg";
      default:
        return "/img/autre.jpg";
    }
  };

  const formatCourseStartTime = (dateString) => {
    if (!dateString) return "";

    try {
      const date = Array.isArray(dateString)
        ? new Date(...dateString)
        : new Date(dateString);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", dateString);
        return "";
      }
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours < 10 ? "0" : ""}${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes}`;
    } catch (error) {
      console.error("Error parsing date");
      return "";
    }
  };

  const getDayOfWeek = (dateString) => {
    if (!dateString) return "";

    try {
      const [year, month, day] = Array.isArray(dateString)
        ? dateString
        : dateString.split("-");
      const date = new Date(year, month - 1, day);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", dateString);
        return "";
      }
      const daysOfWeek = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error("Error parsing date");
      return "";
    }
  };

  const renderDateCell = (date) => {
    if (!date) {
      return "Non défini";
    }

    const timeZone = "Europe/Paris";
    const options = {
      weekday: "long",
      timeZone: timeZone,
    };

    const formattedDate = Array.isArray(date)
      ? new Date(
          date[0],
          date[1] - 1,
          date[2],
          date[3] || 0,
          date[4] || 0
        ).toLocaleDateString("fr-FR", options)
      : new Date(date).toLocaleDateString("fr-FR", options);

    const formattedTime = formatCourseStartTime(date);
    return `${formattedDate} : ${formattedTime}`;
  };

  const renderTimeCell = (date) => {
    if (!date) {
      return "Non défini";
    }

    const formattedTime = formatCourseStartTime(date);
    return formattedTime;
  };

  const handleStart = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = await fetchXsrfToken();
      const response = await axiosInstance.put(
        `/mescours/sta?idCours=${idCours}&emailEleve=${user.name}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      const link = response.data;
      setLink(link);
      setOpen(true);
    } catch (error) {
      console.log("error");
    }
  };

  const handleRatee = async () => {
    if (!rateeClicked) {
      try {
        setRateeClicked(true);
        const csrfToken = await fetchXsrfToken();
        await axiosInstance.put(
          `/mescours/rat?idCours=${idCours}&emailEleve=${user.name}`,
          {},
          {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": csrfToken,
            },
          }
        );
      } catch (error) {
        console.log("error");
      }
    }
  };

  const handleRating = async (event, newValue) => {
    try {
      let csrfToken = await fetchXsrfToken();
      await axiosInstance.put(
        `/mescours/rating?idCours=${idCours}&emailEleve=${user.name}&rating=${newValue}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      csrfToken = await fetchXsrfToken();

      await axiosInstance.put(
        `/cours/rat?idCours=${idCours}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      setRating(newValue);
    } catch (error) {
      console.log("Error");
    }
  };

  return (
    <Container>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          marginTop: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease-in-out",
          ":hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <Typography variant="h3" className="title" gutterBottom>
          {title}
        </Typography>
        <img
          src={choosePicture(
            selectedCours.coursSubject,
            selectedCours.coursLevel
          )}
          alt={title}
          className="image-style"
        />
        <div className="grid-details">
          <Typography variant="h6">
            <strong>
              <u>Détails</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">{description}</Typography>
          <Typography variant="h6">
            <strong>
              <u>Matière</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">
            {coursSubject} / {coursLevelFull}
          </Typography>
          <Typography variant="h6">
            <strong>
              <u>Prix</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">
            {price != null ? `${price} MAD` : "N/A"}
          </Typography>
          <Typography variant="h6">
            <strong>
              <u>Première Séance</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">
            {renderDateCell(date_d, true)}
          </Typography>
          {date_s && (
            <Typography variant="h6">
              <strong>
                <u>Deuxieme Séance</u> :
              </strong>
            </Typography>
          )}
          {date_s && (
            <Typography className="typography-body1">
              {renderDateCell(date_s)}
            </Typography>
          )}
          {date_t && (
            <Typography variant="h6">
              <strong>
                <u>Troisieme Séance</u> :
              </strong>
            </Typography>
          )}
          {date_t && (
            <Typography className="typography-body1">
              {renderDateCell(date_t)}
            </Typography>
          )}
        </div>
        <Typography
          variant="h6"
          className="professorText"
          style={{ marginLeft: "auto", marginTop: "20px" }}
        >
          Professeur: {`${selectedCours?.profNom} ${selectedCours?.profPrenom}`}
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          <strong>
            <u>Emploi du Temps</u> :
          </strong>
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lundi</TableCell>
                <TableCell>Mardi</TableCell>
                <TableCell>Mercredi</TableCell>
                <TableCell>Jeudi</TableCell>
                <TableCell>Vendredi</TableCell>
                <TableCell>Samedi</TableCell>
                <TableCell>Dimanche</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {daysOfWeek.map((day, index) => {
                  let matchedDate = null;
                  if (day === getDayOfWeek(selectedCours.date_d)) {
                    matchedDate = selectedCours.date_d;
                  } else if (day === getDayOfWeek(selectedCours.date_s)) {
                    matchedDate = selectedCours.date_s;
                  } else if (day === getDayOfWeek(selectedCours.date_t)) {
                    matchedDate = selectedCours.date_t;
                  }

                  return (
                    <TableCell
                      key={index}
                      style={{
                        background: matchedDate ? "lightgreen" : "inherit",
                      }}
                    >
                      {matchedDate ? renderTimeCell(matchedDate) : ""}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Paper elevation={3} style={{ padding: "10px" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "#CD5C5C",
              marginBottom: "20px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Commencer la séance
          </Typography>
          <div>
            <Typography>
              <u>Des règles générales à respecter</u> :
            </Typography>
            <List style={{ color: "#000080" }}>
              <ListItem>
                <ListItemText>
                  Veuillez respecter le déroulement de la séance.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  N'hésitez pas à participer dans la séance et soyez attentif au
                  professeur.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Vous pourrez demander une pause de 5 minutes après chaque
                  heure.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Veuillez attendre au moins 15 minutes avant de signaler une
                  séance ratée.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText sx={{ color: "red" }}>
                  <strong>
                    Une Séance signalée ratée ne peut plus être disponible.
                  </strong>
                </ListItemText>
              </ListItem>
            </List>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleStart}
                disabled={rateeClicked || disableStart}
                style={{ marginTop: "10px", marginRight: "10px" }}
              >
                Commencer
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleRatee}
                disabled={rateeClicked}
                style={{ marginTop: "10px" }}
              >
                Seance Ratée:{numRatee}/2
              </Button>
              <Rating
                name="rating"
                style={{ marginLeft: "10px", marginTop: "10px" }}
                value={rating}
                precision={0.5}
                onChange={handleRating}
              />
            </div>
            <Collapse in={open}>
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                Voici le lien pour commencer le cours:{" "}
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </Typography>
              <Typography variant="body2" style={{ marginTop: "10px" }}>
                Veuillez consulter que les liens des sites légitimes telque
                Google, Zoom, Microsoft...
              </Typography>
            </Collapse>
          </div>
        </Paper>
      </Paper>
    </Container>
  );
};
export default MesCoursDetails;
