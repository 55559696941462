import React from "react";
import Slideshow from "./Slider";
import { Zoom } from "react-awesome-reveal";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import "../customCss/Advantages.css";

export const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/biblio");
  };

  const image = {
    url: "/img/biblio.jpg",
    title: "Biblio",
    width: "80%",
  };

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 200,
    borderRadius: "8px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover, &.Mui-focusVisible": {
      zIndex: 1,
      "& .MuiImageBackdrop-root": {
        opacity: 0.15,
      },
      "& .MuiImageMarked-root": {
        opacity: 0,
      },
      "& .MuiTypography-root": {
        border: "4px solid currentColor",
      },
    },
  }));

  const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  });

  const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  }));

  const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  }));

  const ImageMarked = styled("span")(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  }));

  return (
    <>
      <div className="page">
        <Slideshow />
        <br />
        <h6
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Pourquoi choisir E-SWAYE?
        </h6>
      </div>
      <div className="home-section-title">
        <h1 className="home-main-title">Pour un Professeur</h1>
        <div className="home-underline"></div>
      </div>
      <Zoom>
        <section className="home-b-advantages b-advantages-3 b-advantages-3_mod-b b-advantages_3-col">
          <i className="home-b-advantages__icon stroke pe-7s-global"></i>
          <div className="home-b-advantages__inner">
            <h3 className="home-b-advantages__title">
              <a href="biblio">L'endroit idéal</a>
            </h3>
            <div className="home-b-advantages__info">
              Notre site vous permet de créer et diffuser vos cours de soutien
              sans avoir à vous soucier de trouver un centre dédié. Vos cours
              seront diffusés à travers tout le Maroc, vous offrant ainsi la
              possibilité d'attirer des étudiants de toutes les villes.
            </div>
          </div>
        </section>
      </Zoom>
      <br />
      <Zoom>
        <section className="home-b-advantages b-advantages-3 b-advantages-3_mod-b b-advantages_3-col">
          <i className="home-b-advantages__icon stroke pe-7s-global"></i>
          <div className="home-b-advantages__inner">
            <h3 className="home-b-advantages__title">
              <a href="biblio">Gain énorme</a>
            </h3>
            <div className="home-b-advantages__info">
              Créez votre centre et commencez à enseigner vos cours
              immédiatement, depuis n'importe où. Accueillez autant d'étudiants
              que vous le souhaitez, enseignez à distance et gagnez jusqu'à 90 %
              des paiements.
            </div>
          </div>
        </section>
      </Zoom>
      <br />
      <Zoom>
        <section className="home-b-advantages b-advantages-3 b-advantages-3_mod-b b-advantages_3-col">
          <i className="home-b-advantages__icon stroke pe-7s-global"></i>
          <div className="home-b-advantages__inner">
            <h3 className="home-b-advantages__title">
              <a href="biblio">Etant étudiant</a>
            </h3>
            <div className="home-b-advantages__info">
              Vous aurez accès à un large choix de professeurs, des horaires
              flexibles, et des cours de soutien accessibles partout au Maroc.
              Sélectionnez la meilleure offre et progressez avec succès dans
              votre parcours scolaire.
            </div>
          </div>
        </section>
      </Zoom>
      <div className="home-banner-container">
        <img src="/img/Details2.jpg" alt="Banner" />
      </div>
      <br />
      <div className="home-b-advantages__titleb">
        <h3>Accédez à vos cours rapidement et depuis n'importe où</h3>
        <div className="home-underlineb"></div>
        <p>
          Avec notre plateforme en ligne, accédez à vos cours de manière rapide
          et facile, où que vous soyez. Notre interface conviviale vous permet
          de naviguer facilement entre les leçons, de participer à des sessions
          en direct et d'interagir avec vos enseignants et vos camarades de
          classe. Profitez de la flexibilité et de la commodité pour apprendre à
          votre rythme et selon votre emploi du temps.
        </p>
        <br />
        <div className="home-b-advantages__image-container">
          <div className="home-b-advantages__image">
            <div className="home-video-wrapper">
              <iframe
                src="https://www.youtube.com/embed/89F3IoIGKDk?si=vCrzG_JUQRVuEPoU"
                title="Integration pour les élèves"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
            <p>Tutoriel Étudiant</p>
          </div>
          <div className="home-b-advantages__image">
            <div className="home-video-wrapper">
              <iframe
                src="https://www.youtube.com/embed/nFGcNqQmmtY?si=3ggLHEC0g-KYflZv"
                title="Integration pour les professeurs"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
            <p>Tutoriel Professeur</p>
          </div>
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 300,
          width: "100%",
        }}
      >
        <ImageButton
          focusRipple
          style={{
            width: image.width,
          }}
          onClick={handleClick}
        >
          <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: "relative",
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {image.title}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton>
      </Box>
      <div className="home-container">
        <img src="/img/1st.png" alt="1St" className="home-image" />
        <img src="/img/certifie.png" alt="Certifie" className="home-image" />
        <img src="/img/secure.png" alt="Secure" className="home-image" />
      </div>
      <br />
      <br />
    </>
  );
};
