import { ArrowDropDown, Done } from "@mui/icons-material";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axiosInstance from "../../auth/axiosInstance";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import WithdrawDialogU from "../../auth/WithdrawDialogU";
import Loading from "../structure/Loading";
import "../customCss/Cours.css";

const CoursDetails = () => {
  const { idCours } = useParams();
  const [selectedCours, setSelectedCours] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showTextCard, setShowTextCard] = useState(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState(null);
  const { user } = AuthData();
  const daysOfWeek = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const coursLevelMapping = {
    College_1: "1ère Collège",
    College_2: "2ème Collège",
    College_3: "3ème Collège",
    T_C: "Tronc Commun",
    BAC_1: "1ère Année Bac",
    BAC_2: "2ème Année Bac",
    Universitaire: "Universitaire",
    MP: "Prépa MP",
    PSI: "Prépa PSI",
    TSI: "Prépa TSI",
    ECS: "Prépa ECS",
    ECT: "Prépa ECT",
    Tout: "Tous Niveaux",
    Autre: "Autre",
  };

  const [paymentStatus, setPaymentStatus] = useState("Payer/Tester");
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handlePayment = async () => {
    try {
      if (paymentStatus === "Payer/Tester") {
        const csrfToken = await fetchXsrfToken();
        await axiosInstance.post(
          `/mescours`,
          {
            idCours: idCours,
            idEleve: user.id,
            emailEleve: user.name,
          },
          {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": csrfToken,
            },
          }
        );
        const resCours = await axiosInstance.get(
          `/mescours/v?idCours=${idCours}&emailEleve=${user.name}`
        );
        if (resCours.data === false) {
          setPaymentStatus("En cours de validation");
        } else if (resCours.data === true) {
          setPaymentStatus("Payé");
        }
      }
    } catch (error) {
      console.log("Erreur ");
    }
  };

  const handlePay = async (idCours) => {
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.put(
        `/mescours/pay?idCours=${idCours}&emailEleve=${user.name}&id=${user.id}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      setPaymentSuccess(true);
    } catch (error) {
      console.log("Error");
    }
  };

  useEffect(() => {
    const fetchCoursDetails = async () => {
      try {
        const response = await axiosInstance.get(`/cours/i?idCours=${idCours}`);
        setSelectedCours(response.data);
      } catch (error) {
        console.error("Error ");
      } finally {
        setLoading(false);
      }
    };

    fetchCoursDetails();

    const fetchPaymentStatus = async () => {
      try {
        const resCours = await axiosInstance.get(
          `/mescours/v?idCours=${idCours}&emailEleve=${user.name}`
        );
        if (resCours.data === false) {
          setPaymentStatus("En cours de validation");
        } else if (resCours.data === true) {
          setPaymentStatus("Payé");
        }
      } catch (error) {
        console.error("Error ");
      }
    };
    fetchPaymentStatus();
    if (paymentSuccess) {
      fetchCoursDetails();
    }
  }, [idCours, user.name, paymentSuccess]);

  if (loading) {
    return <Loading />;
  }

  if (!selectedCours) {
    return <Typography variant="h4">Cours Indisponible </Typography>;
  }

  const {
    title,
    coursSubject,
    coursLevel,
    description,
    price,
    date_d,
    date_s,
    date_t,
  } = selectedCours;

  const coursLevelFull = coursLevelMapping[coursLevel] || coursLevel;
  const coursAbr = coursSubject.substring(0, 2).toUpperCase();

  const choosePicture = (coursSubject, coursLevel) => {
    const specialLevels = [
      "BAC_1",
      "BAC_2",
      "MP",
      "PSI",
      "TSI",
      "ECS",
      "ECT",
      "Universitaire",
    ];

    switch (coursSubject) {
      case "maths":
        return specialLevels.includes(coursLevel)
          ? "/img/maths_ad.jpg"
          : "/img/maths.jpg";
      case "pc":
        return specialLevels.includes(coursLevel)
          ? "/img/pc_ad.jpg"
          : "/img/pc.jpg";
      case "science_ingenieur":
        return specialLevels.includes(coursLevel)
          ? "/img/si_ad.jpg"
          : "/img/si.jpg";
      case "svt":
        return specialLevels.includes(coursLevel)
          ? "/img/svt_ad.jpg"
          : "/img/svt.jpg";
      case "arabe":
        return "/img/arabe.jpg";
      case "anglais":
        return specialLevels.includes(coursLevel)
          ? "/img/eng_ad.jpg"
          : "/img/english.jpg";
      case "francais":
        return specialLevels.includes(coursLevel)
          ? "/img/fr_ad.jpg"
          : "/img/fr.jpg";
      case "espagnol":
        return "/img/espagnol.jpg";
      case "education_islamique":
        return "/img/E_I.jpg";
      case "philosophie":
        return "/img/philosophie.jpg";
      case "histoire":
        return specialLevels.includes(coursLevel)
          ? "/img/histoire_ad.jpg"
          : "/img/histoire.jpg";
      case "info":
        return "/img/info.jpg";
      case "electronique":
        return "/img/electronique.jpg";
      case "economie":
        return specialLevels.includes(coursLevel)
          ? "/img/eco_ad.jpg"
          : "/img/economie.jpg";
      case "autre":
        return "/img/autre.jpg";
      default:
        return "/img/autre.jpg";
    }
  };

  const formatCourseStartTime = (dateString) => {
    if (!dateString) return "";

    try {
      const date = Array.isArray(dateString)
        ? new Date(...dateString)
        : new Date(dateString);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", dateString);
        return "";
      }
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours < 10 ? "0" : ""}${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes}`;
    } catch (error) {
      console.error("Error parsing date");
      return "";
    }
  };

  const getDayOfWeek = (dateString) => {
    if (!dateString) return "";

    try {
      const [year, month, day] = Array.isArray(dateString)
        ? dateString
        : dateString.split("-");
      const date = new Date(year, month - 1, day);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", dateString);
        return "";
      }
      const daysOfWeek = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error("Error parsing date");
      return "";
    }
  };

  const renderDateCell = (date) => {
    if (!date) {
      return "Non défini";
    }

    const timeZone = "Europe/Paris";
    const options = {
      weekday: "long",
      timeZone: timeZone,
    };

    const formattedDate = Array.isArray(date)
      ? new Date(
          date[0],
          date[1] - 1,
          date[2],
          date[3] || 0,
          date[4] || 0
        ).toLocaleDateString("fr-FR", options)
      : new Date(date).toLocaleDateString("fr-FR", options);

    const formattedTime = formatCourseStartTime(date);
    return `${formattedDate} : ${formattedTime}`;
  };

  const renderTimeCell = (date) => {
    if (!date) {
      return "Non défini";
    }

    const formattedTime = formatCourseStartTime(date);
    return formattedTime;
  };

  return (
    <Container>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          marginTop: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease-in-out",
          ":hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <div className="flex-end-bold">
          NO: <ClassOutlinedIcon />
          <Typography variant="body3" color="text.secondary">
            {coursAbr}-{idCours}
          </Typography>
        </div>
        <Typography variant="h3" className="title" gutterBottom>
          {title}
        </Typography>
        <img
          src={choosePicture(
            selectedCours.coursSubject,
            selectedCours.coursLevel
          )}
          alt={title}
          className="image-style"
        />
        <div className="grid-details">
          <Typography variant="h6">
            <strong>
              <u>Détails</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">{description}</Typography>
          <Typography variant="h6">
            <strong>
              <u>Matière</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">
            {coursSubject} / {coursLevelFull}
          </Typography>
          <Typography variant="h6">
            <strong>
              <u>Prix</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">
            {price != null ? `${price} MAD` : "N/A"}
          </Typography>
          <Typography variant="h6">
            <strong>
              <u>Première Séance</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">
            {renderDateCell(date_d, true)}
          </Typography>
          {date_s && (
            <Typography variant="h6">
              <strong>
                <u>Deuxième Séance</u> :
              </strong>
            </Typography>
          )}
          {date_s && (
            <Typography className="typography-body1">
              {renderDateCell(date_s)}
            </Typography>
          )}
          {date_t && (
            <Typography variant="h6">
              <strong>
                <u>Troisième Séance</u> :
              </strong>
            </Typography>
          )}
          {date_t && (
            <Typography className="typography-body1">
              {renderDateCell(date_t)}
            </Typography>
          )}
        </div>

        <Typography
          variant="h6"
          className="professorText"
          style={{ marginLeft: "auto", marginTop: "20px" }}
        >
          Professeur: {`${selectedCours?.profNom} ${selectedCours?.profPrenom}`}
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          <strong>
            <u>Emploi du Temps</u> :
          </strong>
        </Typography>

        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lundi</TableCell>
                <TableCell>Mardi</TableCell>
                <TableCell>Mercredi</TableCell>
                <TableCell>Jeudi</TableCell>
                <TableCell>Vendredi</TableCell>
                <TableCell>Samedi</TableCell>
                <TableCell>Dimanche</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {daysOfWeek.map((day, index) => {
                  let matchedDate = null;
                  if (day === getDayOfWeek(selectedCours.date_d)) {
                    matchedDate = selectedCours.date_d;
                  } else if (day === getDayOfWeek(selectedCours.date_s)) {
                    matchedDate = selectedCours.date_s;
                  } else if (day === getDayOfWeek(selectedCours.date_t)) {
                    matchedDate = selectedCours.date_t;
                  }

                  return (
                    <TableCell
                      key={index}
                      style={{
                        background: matchedDate ? "lightgreen" : "inherit",
                      }}
                    >
                      {matchedDate ? renderTimeCell(matchedDate) : ""}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "20px", marginLeft: "auto" }}
          onClick={() => setShowTextCard(!showTextCard)}
        >
          Rejoindre le Cours
          <ArrowDropDown style={{ marginLeft: "5px" }} />
        </Button>

        <Paper
          elevation={3}
          className={showTextCard ? "textCardVisible" : "textCardHidden"}
          style={{
            transition: "height 0.5s ease-in-out",
            overflow: "hidden",
          }}
        >
          <Typography variant="body1" style={{ marginLeft: "20px" }}>
            <br />
            <strong>
              Veuillez suivre les instructions pour régler les frais mensuels et
              intégrer le cours :
            </strong>
            <br />
            <br />
            <span role="img" aria-label="ticket">
              🎟️
            </span>{" "}
            Effectuez un virement vers l'un des comptes bancaires indiqués.
            <br />
            <br />
            <span role="img" aria-label="ticket">
              🎟️
            </span>{" "}
            Envoyez le reçu avec votre adresse email utilisée et le numéro du
            cours (ex: MA-17) via WhatsApp.
            <br />
            <br />
            <span role="img" aria-label="ticket">
              🎟️
            </span>{" "}
            Une fois le paiement validé, le cours apparaîtra dans vos cours
            payés, et vous pourrez le consulter.
            <br />
            <br />
            <strong>
              <span style={{ color: "IndianRed" }}>
                {" "}
                N'oubliez pas que vous avez une séance gratuite à votre
                disposition.
              </span>
              <br />
              <br />
              Vous avez également la possibilité de payer en utilisant votre
              solde.
            </strong>
          </Typography>
          <Typography variant="body1" style={{ marginLeft: "20px" }}>
            <br />
            Le paiement doit être effectué sur le compte des employés suivants,
            selon le programme indiqué.
            <br />
            Veuillez les contacter pour toute information concernant le RIB.
            <br />
            <br />
            <strong>
              <u>Lundi, Mercredi, Vendredi, Dimanche :</u>
            </strong>
            <br />
            <span
              style={{
                color: "green",
                marginLeft: "50px",
                fontFamily: "cursive",
              }}
            >
              Mr. Ali : <u>0656-214252</u>
            </span>
            <br />
            CIH , ATTIJARI WAFABANK , BANQUE POPULAIRE
            <br />
            <br />
            <strong>
              <u>Mardi, Jeudi, Samedi :</u>
            </strong>
            <br />
            <span
              style={{
                color: "green",
                marginLeft: "50px",
                fontFamily: "cursive",
              }}
            >
              Mr. Hamza : <u>0709-446369</u>
            </span>
            <br />
            CIH , BARID BANK
            <br />
            <br />
          </Typography>
          <Typography variant="body1" style={{ marginLeft: "20px" }}>
            Veuillez contacter l'administration pour toute question ou
            réclamation :
            <br />
            <span>&nbsp;</span> <strong> sup.eswaye@eswaye.com </strong>
            <br />
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            {paymentStatus === "En cours de validation" && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenWithdrawDialog(true);
                  setWithdrawalData({ idTarget: idCours, idUser: user.id });
                }}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Payer par Solde
              </button>
            )}
            <button
              onClick={handlePayment}
              disabled={paymentStatus !== "Payer/Tester"}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor:
                  paymentStatus === "Payé"
                    ? "#4caf50"
                    : paymentStatus === "En cours de validation"
                    ? "#ccc"
                    : "#1976d2",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor:
                  paymentStatus !== "Payer/Tester" ? "not-allowed" : "pointer",
              }}
            >
              {paymentStatus === "Payé" ? (
                <>
                  <Done style={{ marginRight: "5px" }} />
                  Payé
                </>
              ) : (
                paymentStatus
              )}
            </button>
          </div>
        </Paper>
      </Paper>
      <WithdrawDialogU
        open={openWithdrawDialog}
        message="Veuillez saisir votre mot de passe pour continuer."
        handleSubmit={handlePay}
        handleClose={() => setOpenWithdrawDialog(false)}
        withdrawalData={withdrawalData}
      />
    </Container>
  );
};
export default CoursDetails;
