import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Container,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { Paper, Collapse } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axiosInstance from "../../auth/axiosInstance";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import { AuthData } from "../../auth/AuthWrapper";
import WithdrawDialog from "../../auth/WithdrawDialog";

const CoursControl = () => {
  const { user } = AuthData();
  const [cours, setCours] = useState({});
  const [coursUpdate, setCoursUpdate] = useState({
    title: "",
    description: "",
  });
  const { idCours } = useParams();
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [isPayer, setIsPayer] = useState({});
  const [rows, setRows] = useState([]);
  const [ligne, setLigne] = useState([]);
  const [nomProf, setNomProf] = useState("");
  const [matiere, setMatiere] = useState("");
  const [pourcentage, setPourcentage] = useState("80");
  const [withdrawalData, setWithdrawalData] = useState(null);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);
  const [openWithdrawDialog2, setOpenWithdrawDialog2] = useState(false);
  const [openWithdrawDialog3, setOpenWithdrawDialog3] = useState(false);
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");

  const formatDateTime = (dateTimeArray) => {
    if (!dateTimeArray || dateTimeArray.length !== 7) return "";
    const [year, month, day, hour, minute, second, milliseconds] =
      dateTimeArray;
    const dateTime = new Date(
      year,
      month - 1,
      day,
      hour,
      minute,
      second,
      milliseconds
    );
    const formattedDate = dateTime.toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = dateTime.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} : ${formattedTime}`;
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `/mescours/idc?idCours=${idCours}`
      );
      const fetchedRows = response.data;
      const coursRes = await axiosInstance.get(`/cours/i?idCours=${idCours}`);
      setCours(coursRes.data);
      setCoursUpdate({
        title: coursRes.data.title,
        description: coursRes.data.description,
      });

      const filteredRows = fetchedRows.filter((row) => row.paye === true);

      const nbr = filteredRows.length;
      const matiere = coursRes.data.coursSubject;
      const niveau = coursRes.data.coursLevel;
      const prix = coursRes.data.price;
      const prof = coursRes.data.profPrenom + " " + coursRes.data.profNom;
      const total = prix * nbr;
      setNomProf(prof);
      setMatiere(matiere);

      const updatedLigne = {
        nbr: nbr,
        matiere: matiere,
        niveau: niveau,
        prix: prix,
        prof: prof,
        pourcentage: pourcentage + "%",
        total: total * (parseInt(pourcentage) / 100) + " DHS",
      };
      setLigne([updatedLigne]);

      const initialIsPayerState = fetchedRows.reduce((acc, curr) => {
        acc[curr.idCours] = false;
        return acc;
      }, {});
      setIsPayer(initialIsPayerState);
      setRows(fetchedRows);
    } catch (error) {
      console.log("Error fetching data ");
    }
  }, [idCours, pourcentage]);

  const handlePourcentage = (e) => {
    setPourcentage(e.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      field: "idEleve",
      headerName: "ID ELEVE",
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "emailEleve",
      headerName: "EMAIL",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "paye",
      headerName: "STATUT",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <strong style={{ color: params.value ? "green" : "black" }}>
          {params.value ? "Payé" : "Non Payé"}
        </strong>
      ),
    },
    {
      field: "test",
      headerName: "SEANCE GRATUITE",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <strong style={{ color: params.value ? "green" : "black" }}>
          {params.value ? "Non Consommée" : " Consommée"}
        </strong>
      ),
    },
    {
      field: "rateeUne",
      headerName: "1ere RATEE",
      width: 200,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.rateeUne ? formatDateTime(params.row.rateeUne) : "";
      },
    },
    {
      field: "raateeDeux",
      headerName: "2eme RATEE",
      width: 200,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.raateeDeux
          ? formatDateTime(params.row.raateeDeux)
          : "";
      },
    },
    {
      field: "payer",
      headerName: "PAYER",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          disabled={params.row.paye || isPayer[params.row.idCours]}
          onClick={(e) => {
            e.stopPropagation();
            setOpenWithdrawDialog(true);
            setWithdrawalData({
              idTarget: params.row.idCours,
              idUser: user.id,
              email: params.row.emailEleve,
            });
          }}
        >
          {params.row.paye ? "PAYÉ" : "PAYER"}
        </Button>
      ),
    },
    {
      field: "retirer",
      headerName: "RETIRER",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            setOpenWithdrawDialog2(true);
            setWithdrawalData({
              idTarget: params.row.idCours,
              idUser: user.id,
              email: params.row.emailEleve,
            });
          }}
        >
          Retirer
        </Button>
      ),
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCoursUpdate((prevCoursUpdate) => ({
      ...prevCoursUpdate,
      [name]: value,
    }));
  };

  const handleChange = async () => {
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.put(`/cours/cha?idCours=${idCours}`, coursUpdate, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      });
      setMessage("Modification exécutée avec succès!");
      setOpenMessage(true);
    } catch (error) {
      console.error("Error updating");
    }
  };

  const handleRetirer = async (idCours, email) => {
    const csrfToken = await fetchXsrfToken();
    axiosInstance
      .delete(
        `/mescours/del?idCours=${idCours}&emailEleve=${email}&id=${user.id}`,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      )
      .then(() => {
        setMessage(`L'eleve : ${email} est retirer du cours avec Succes`);
        setOpenMessage(true);
        fetchData();
      })
      .catch((error) => {
        console.error("Une erreur de retirer l'eleve ");
      });
  };

  const handlePayer = async (idCours, email) => {
    setIsPayer((prevState) => ({
      ...prevState,
      [idCours]: true,
    }));
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.put(
        `/mescours/pay?idCours=${idCours}&emailEleve=${email}&id=${user.id}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      setMessage(`Paiement d'eleve : ${email} est traite `);
      setOpenMessage(true);
      fetchData();
    } catch (error) {
      console.log("Erreur du paiement ");
    }
  };

  const handleReset = async () => {
    try {
      const csrfToken = await fetchXsrfToken();
      const msg = await axiosInstance.put(
        `/mescours/res?idCours=${idCours}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      setMessage(msg.data);
      setOpenMessage(true);
      fetchData();
    } catch (error) {
      console.log("Erreur de reinitialisation :");
    }
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleControl = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = await fetchXsrfToken();
      const response = await axiosInstance.get(
        `/cours/get?idCours=${idCours}`,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      const link = response.data;
      setLink(link);
      setOpen(true);
    } catch (error) {
      console.log("error");
    }
  };

  const resume = [
    {
      field: "prof",
      headerName: "PROF",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nbr",
      headerName: "NOMBRE ELEVE",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "matiere",
      headerName: "MATIERE",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "niveau",
      headerName: "NIVEAU",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "prix",
      headerName: "PRIX MATIERE",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pourcentage",
      headerName: "POURCENTAGE",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "total",
      headerName: "TOTAL A VERSER",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
  ];

  const exportToPDF = () => {
    const months = [
      "JANVIER",
      "FÉVRIER",
      "MARS",
      "AVRIL",
      "MAI",
      "JUIN",
      "JUILLET",
      "AOÛT",
      "SEPTEMBRE",
      "OCTOBRE",
      "NOVEMBRE",
      "DÉCEMBRE",
    ];
    const currentDate = new Date();
    const currentMonth = months[currentDate.getMonth()];

    const doc = new jsPDF();

    doc.setFont("times");
    doc.setFontSize(14);

    const titleWidth =
      (doc.getStringUnitWidth(
        `ÉTAT DES PAIEMENTS POUR LE MOIS DE ${currentMonth}`
      ) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    const pageWidth = doc.internal.pageSize.getWidth();
    const titleX = (pageWidth - titleWidth) / 2;

    doc.text("E-SWAYE", 10, 15);
    doc.text("Hay Riad - RABAT", 10, 25);
    doc.text("eswaye-support@gmail.com", 10, 35);

    doc.text("Professeur:   " + nomProf, pageWidth - 60, 40);
    doc.text("Matiere:\t" + matiere, pageWidth - 60, 48);

    doc.setFontSize(13);
    doc.setFont("times", "normal");
    doc.setTextColor(0, 0, 128);
    doc.text(
      "  Nous vous remercions pour votre dévouement et votre travail acharné dans l'enseignement\n" +
        " de cette matière. Le tableau ci-dessous présente un résumé de vos activités pour ce mois-ci.",
      10,
      80
    );

    const columns = resume.map((col) => col.headerName);
    const data = ligne.map((row) => resume.map((col) => row[col.field]));
    doc.setTextColor(255, 127, 80);
    doc.text(`ÉTAT DES PAIEMENTS POUR LE MOIS DE ${currentMonth}`, titleX, 105);
    doc.autoTable({
      head: [columns],
      body: data,
      startY: 110,
    });

    doc.save(`${matiere} ${nomProf}.pdf`);
  };

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setOpenWithdrawDialog3(true);
          setWithdrawalData({
            idTarget: idCours,
            idUser: user.id,
          });
        }}
        sx={{
          marginTop: "20px",
          marginLeft: "auto",
          marginRight: "40px",
          alignContent: "center",
          display: "block",
        }}
        variant="outlined"
        color="error"
      >
        Reinitialiser Cours
      </Button>
      {cours.title && (
        <div
          style={{ textAlign: "center", marginTop: "10px", color: "#6495ED" }}
        >
          <Typography variant="h3" gutterBottom>
            <u>{cours.title}</u>
          </Typography>

          <TableContainer
            component={Paper}
            elevation={2}
            style={{
              marginTop: "20px",
              maxWidth: "600px",
              margin: "auto",
              padding: "20px",
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: "10%" }}>Title</TableCell>{" "}
                  <TableCell>
                    <TextField
                      multiline
                      name="title"
                      value={coursUpdate.title}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "10%" }}>Description</TableCell>
                  <TableCell>
                    <TextField
                      multiline
                      name="description"
                      value={coursUpdate.description}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            variant="contained"
            color="primary"
            onClick={handleChange}
            style={{ marginTop: "20px" }}
          >
            Save Changes
          </Button>
        </div>
      )}
      <br />
      <div
        style={{
          height: 400,
          width: "90%",
          marginLeft: "3%",
          marginBottom: "40px",
        }}
      >
        <DataGrid
          getRowId={(row) => row.idEnregistrement}
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection={true}
        />
      </div>
      <br />
      <Container>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleControl}
            style={{ marginTop: "10px", marginRight: "10px" }}
          >
            Controler Seance
          </Button>
          <Paper>
            <Collapse in={open}>
              <Typography variant="body1" style={{ margin: "10px" }}>
                Voici le lien pour controler le cours:{" "}
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </Typography>
              <Typography variant="body2" style={{ margin: "10px" }}>
                Veuillez consulter que les liens des sites légitimes telque
                Google, Zoom, Microsoft...
              </Typography>
            </Collapse>
          </Paper>
        </div>
      </Container>

      <Snackbar
        open={openMessage}
        autoHideDuration={4000}
        onClose={handleCloseMessage}
        message={message}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      <br />
      <div
        style={{ textAlign: "center", marginBottom: "20px", marginTop: "20px" }}
      >
        <h1 style={{ fontSize: "22px", color: "#FF5733", fontWeight: "bold" }}>
          <u>RAPPORT SYNTHÉTIQUE</u>
        </h1>
        <label>
          <strong>Pourcentage Prof :</strong>
        </label>
        <label>
          <Checkbox
            checked={pourcentage === "80"}
            value="80"
            color="success"
            onChange={handlePourcentage}
          />
          80%
        </label>
        <label>
          <Checkbox
            checked={pourcentage === "85"}
            value="85"
            sx={{
              color: "#FFD700",
              "&.Mui-checked": {
                color: "#FFD700",
              },
            }}
            onChange={handlePourcentage}
          />
          85%
        </label>
        <label>
          <Checkbox
            checked={pourcentage === "90"}
            value="90"
            sx={{
              color: "#F08080",
              "&.Mui-checked": {
                color: "#F08080",
              },
            }}
            onChange={handlePourcentage}
          />
          90%
        </label>
      </div>
      <Paper
        elevation={3}
        style={{
          height: 250,
          width: "65%",
          marginLeft: "auto",
          marginRight: "40px",
        }}
      >
        <DataGrid
          getRowId={(row) => row.prof}
          rows={ligne}
          columns={resume}
          pageSize={5}
          checkboxSelection={false}
        />
      </Paper>
      <br />
      <Button
        onClick={exportToPDF}
        sx={{
          marginLeft: "auto",
          marginRight: "40px",
          alignContent: "center",
          display: "block",
        }}
        variant="contained"
        color="primary"
      >
        Export PDF
      </Button>
      <WithdrawDialog
        open={openWithdrawDialog}
        message="Veuillez entrer le mot de passe pour continuer."
        handleSubmit={handlePayer}
        handleClose={() => setOpenWithdrawDialog(false)}
        withdrawalData={withdrawalData}
      />

      <WithdrawDialog
        open={openWithdrawDialog2}
        message="Veuillez entrer le mot de passe pour continuer."
        handleSubmit={handleRetirer}
        handleClose={() => setOpenWithdrawDialog2(false)}
        withdrawalData={withdrawalData}
      />

      <WithdrawDialog
        open={openWithdrawDialog3}
        message="Veuillez entrer le mot de passe pour continuer."
        handleSubmit={handleReset}
        handleClose={() => setOpenWithdrawDialog3(false)}
        withdrawalData={withdrawalData}
      />
      <br />
    </>
  );
};

export default CoursControl;