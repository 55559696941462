import { FaCoins, FaUser } from "react-icons/fa";
import { Link, Route, Routes } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import CoursDetails from "../pages/CoursDetails";
import { nav } from "./navigation";

export const RenderRoutes = () => {
  const { user } = AuthData();

  return (
    <Routes>
      {nav.map((r, i) => {
        if (r.isPrivate) {
          if (
            user &&
            user.role &&
            ((r.isProf && user.role === "prof") ||
              (r.isEleve && user.role === "eleve") ||
              (r.isADR &&
                user.role !== "prof" &&
                user.role !== "eleve" &&
                user.role !== "adminModir") ||
              (r.isAdmin && user.role === "adminModir"))
          ) {
            return <Route key={i} path={r.path} element={r.element} />;
          }
        } else {
          return <Route key={i} path={r.path} element={r.element} />;
        }
        return null;
      })}
      <Route path="/coursdetails/:courseId" element={<CoursDetails />} />
    </Routes>
  );
};

export const RenderMenu = () => {
  const { user, logout } = AuthData();

  const MenuItem = ({ r }) => {
    return (
      <div className="menuItem">
        <Link to={r.path}>{r.name} </Link>
      </div>
    );
  };

  return (
    <div className="menu">
      <div className="menuItem">
        <Link to="/">
          <img src="https://eswaye.com/Elogo.png" alt="Logo" className="logo" />
        </Link>
      </div>
      {nav.map((r, i) =>
        r.isMenu &&
        (!r.isPrivate ||
          (user &&
            user.role &&
            ((r.isProf && user.role === "prof") ||
              (r.isEleve && user.role === "eleve") ||
              (r.isADR &&
                user.role !== "prof" &&
                user.role !== "eleve" &&
                user.role !== "adminModir") ||
              (r.isAdmin && user.role === "adminModir")))) ? (
          <MenuItem key={i} r={r} />
        ) : null
      )}

      {user && user.role ? (
        <div className="menuItem">
          <div className="balance">
            <span
              style={{
                fontWeight: "bold",
                color: "green",
                marginRight: "10px",
              }}
            >
              Solde:
            </span>
            <span>{user.solde}</span>
            &nbsp;&nbsp;
            <FaCoins style={{ marginTop: "5px" }} />
          </div>
          <Link to="/" onClick={logout}>
            Se déconnecter
          </Link>
        </div>
      ) : (
        <>
          <div className="dropdown">
            <button className="dropbtn">Compte</button>
            <div className="dropdown-content">
              <Link to="Login">Se connecter</Link>
              <Link to="Register">Créer un compte</Link>
            </div>
          </div>
          <FaUser className="userIcon" />
        </>
      )}
    </div>
  );
};
