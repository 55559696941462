import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { Button, CardActions } from "@mui/material";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";

const coursLevelMapping = {
  College_1: "1ère Collège",
  College_2: "2ème Collège",
  College_3: "3ème Collège",
  T_C: "Tronc Commun",
  BAC_1: "1ère Année Bac",
  BAC_2: "2ème Année Bac",
  Universitaire: "Universitaire",
  MP: "Prépa MP",
  PSI: "Prépa PSI",
  TSI: "Prépa TSI",
  ECS: "Prépa ECS",
  ECT: "Prépa ECT",
  Tout: "Tous Niveaux",
  Autre: "Autre",
};

const CustomCard = ({
  id,
  imageSource,
  titre,
  description,
  prix,
  coursSubject,
  coursLevel,
  profName,
  ecole,
  ville,
  url,
  isVerified,
  rating,
  count,
}) => {
  const coursLevelFull = coursLevelMapping[coursLevel] || coursLevel;
  const coursAbr = coursSubject.substring(0, 2).toUpperCase();

  return (
    <Card
      sx={{
        width: "90%",
        marginLeft: "5%",
        marginRight: "5%",
        position: "relative",
        transition: "box-shadow 0.5s",
        "&:hover": {
          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
        },
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CardActionArea
        component={Link}
        to={`/${url}/${id}`}
        sx={{ textDecoration: "none", color: "inherit" }}
      >
        <CardMedia
          component="img"
          height="160"
          image={imageSource}
          alt="a wallpaper"
        />
        <CardContent
          style={{ flex: "1 0 auto", display: "flex", flexDirection: "column" }}
        >
          <div style={{ flex: 1 }}>
            <Typography gutterBottom variant="h6" component="div">
              <u>{titre}</u>
            </Typography>
            <div
              style={{
                minHeight: "60px",
                maxHeight: "70px",
                overflowY: "auto",
              }}
            >
              {" "}
              <Typography variant="body2" color="text.secondary">
                {description ? description : "Description du Cours"}
              </Typography>
            </div>
            <Typography variant="body2" color="text.secondary">
              <strong>Prix:</strong> {prix != null ? prix + " MAD" : "N/A"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Matière : </strong>
              {coursSubject} - {coursLevelFull}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Rating name="rating" value={rating} precision={0.5} readOnly />
              <span className="votes">{count} avis</span>
            </div>
            <hr />
            <Typography variant="body2" color="text.secondary">
              <strong>Professeur :</strong> {profName} &nbsp;
              {isVerified && <VerifiedIcon sx={{ color: "blue" }} />}
              <br />
              <span style={{ marginLeft: "25px" }}>
                {ecole} - {ville}
              </span>
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontWeight: "bold",
            }}
          >
            NO: <ClassOutlinedIcon />
            <Typography variant="body3" color="text.secondary">
              {coursAbr}-{id}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          animation: "mymove 5s linear infinite",
          backgroundColor: "PowderBlue",
          "@keyframes mymove": {
            "0%": { backgroundColor: "LightSkyBlue" },
            "50%": { backgroundColor: "PaleTurquoise" },
            "100%": { backgroundColor: "LightSkyBlue" },
          },
        }}
      >
        <Button
          variant="contained"
          component={Link}
          to={`/${url}/${id}`}
          sx={{
            borderRadius: 2,
            justifyContent: "center",
            color: "white",

            "&:hover": {
              background: "linear-gradient(45deg, #1565c0 30%, #2196f3 90%)",
            },
          }}
        >
          Plus de Détails
        </Button>
      </CardActions>
    </Card>
  );
};

CustomCard.propTypes = {
  id: PropTypes.number,
  titre: PropTypes.string,
  imageSource: PropTypes.string,
  description: PropTypes.string,
  profName: PropTypes.string,
  ecole: PropTypes.string,
  ville: PropTypes.string,
  coursSubject: PropTypes.string,
  coursLevel: PropTypes.string,
  prix: PropTypes.number,
  isVerified: PropTypes.bool,
  rating: PropTypes.number,
  count: PropTypes.number,
  url: PropTypes.string,
};
export default CustomCard;
